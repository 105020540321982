.otp-button {
  margin-top: 20px !important;
  /* background-color:#4bdced !important; */
  padding: 8px 25px !important;
  border-radius: 20px !important;
}
.back-button {
  margin-top: 20px !important;
  padding: 8px 25px !important;
  border-radius: 20px !important;
  margin-right: 20px !important;
}
