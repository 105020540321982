.profile-img-log {
  width: 50px !important;
  height: 50px !important;
  border: 2px solid #f99117 !important;
  border-radius: 50% !important;
}
.img-view {
  width: 150px !important;
  height: 150px !important;
  border: 2px solid #f99117 !important;
}
.photo-icon {
  font-size: 25px !important;

  color: black;
}
.custom-button-photo {
  background-color: transparent !important;
  box-shadow: none !important;
  margin-left: -20px !important;
  height: auto !important;
}
.pdf-form {
  input {
    margin-bottom: 5.2px !important;
  }
}
