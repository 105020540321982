.welcome-grid {
  text-align: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.data-grid {
  margin: 0 auto !important;
  padding-top: 50px !important;
}
.person-image-dashboard {
  height: 100px !important;
}
.report-grid {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}
.report-div {
  padding-top: 50px !important;
}
.dashboard-card {
  border-radius: 4px !important;
  display: flex !important;
  margin-bottom: 10px !important;
  // width: 260px !important;
  // justify-content: center !important;
  align-items: center !important;
  border: 2px rgba(0, 0, 0, 0.02) !important;
  // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  // box-shadow:box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; !important;
}
.orange-back {
  background-color: hsl(32.09deg 87.04% 48.43%) !important;
}
.green-back {
  background-color: hsl(109, 31%, 31%) !important;
}
.blue-back {
  background-color: hsl(252, 94%, 63%) !important;
}
.red-back {
  background-color: hsl(11, 87%, 48%) !important;
}
.volunteer-image {
  height: 80px !important;
  width: 80px !important;
  color: white !important;
}
